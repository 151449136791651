<template>
    <div class="charger-history">
      <h4>Charger History</h4>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Time</th>
            <th>Duration</th>
            <th>User Type</th>
            <th>Charger Type</th>
            <th>Station Type</th>
            <th>Energy Delivered</th>
            <th>Average Power</th>
            <th>Electricity Costs</th>
            <th>Profit</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>8:27 am</td>
            <td>0:35:30</td>
            <td>Primary</td>
            <td>UkContact PSS Type 1</td>
            <td>Commercial</td>
            <td>7.7 kWh</td>
            <td>$0.20</td>
            <td>$0.30</td>
            <td>$6.02</td>
          </tr>
          <!-- Add more rows as needed -->
        </tbody>
      </table>
    </div>
  </template>
  
  <style scoped>
  .history {
    background: #fff;
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  th, td {
    padding: 10px;
    text-align: left;
  }
  </style>
  