<template>
    <div class="chargers-utilization">
      <h4>Chargers Utilization</h4>
      <div class="chart">
        <!-- Add your chart implementation here -->
        <p>73% used</p>
        <p>25% not used</p>
      </div>
    </div>
  </template>
  
  <style scoped>
  .chargers-utilization {
    background: #fff;
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  .chart {
    width: 100%;
    height: 200px;
    background: #f9f9f9;
    margin-top: 20px;
    position: relative;
  }
  .chart p {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  </style>
  