<template>
    <div id="app">
      <AppHeader />
      <DashboardStats />
      <div class="main-content">
        <ManagedChargers />
        <ChargersUtilization />
        <ChargerHistory />
      </div>
    </div>
  </template>
  
  <script>
  import AppHeader from './components/AppHeader.vue';
  import DashboardStats from './components/DashboardStats.vue';
  import ManagedChargers from './components/ManagedChargers.vue';
  import ChargersUtilization from './components/ChargersUtilization.vue';
  import ChargerHistory from './components/ChargerHistory.vue';
  
  export default {
    name: 'App',
    components: {
      AppHeader,
      DashboardStats,
      ManagedChargers,
      ChargersUtilization,
      ChargerHistory,
    },
  };
  </script>
  
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
  
  .main-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
  }
  </style>
  