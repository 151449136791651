<!-- src/components/DashboardStats.vue -->
<template>
    <div class="dashboard-stats">
      <div class="stat-item">
        <h4>Managing</h4>
        <p>5 Stations</p>
        <p>10 Outlets</p>
      </div>
      <div class="stat-item">
        <h4>Currently</h4>
        <p>4 In Use</p>
        <p>1 Not In Use</p>
      </div>
      <div class="stat-item">
        <h4>Electricity Consumed</h4>
        <p>2560 kW</p>
        <p><span class="stat-decrease">↓ 3%</span> vs last month</p>
      </div>
      <div class="stat-item">
        <h4>Income</h4>
        <p>$6125</p>
        <p><span class="stat-increase">↑ 12%</span> vs last month</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DashboardStats',
  };
  </script>
  
  <style scoped>
  .dashboard-stats {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #fff;
    margin: 20px 0;
  }
  .stat-item {
    flex: 1;
    margin: 0 10px;
    background: #f9f9f9;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .stat-decrease {
    color: red;
  }
  .stat-increase {
    color: green;
  }
  </style>
  