<template>
  <header class="app-header">
    <div class="logo">imoto</div>
    <nav class="nav">
      <a href="#">Dashboard</a>
      <a href="#">Access</a>
      <a href="#">Station</a>
      <a href="#">Payment</a>
      <a href="#">Support</a>
      <a href="#">Notifications</a>
      <a href="#">Report</a>
    </nav>
    <div class="user-info">
      <i class="bell-icon"></i>
      <div class="user-avatar"></div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
};
</script>

<style scoped>
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #000;
  color: #fff;
}
.logo {
  font-size: 24px;
}
.nav a {
  margin: 0 10px;
  color: #fff;
  text-decoration: none;
}
.user-info {
  display: flex;
  align-items: center;
}
.bell-icon {
  width: 24px;
  height: 24px;
  /* background: url('/path-to-bell-icon.png') no-repeat center center; */
  background-size: contain;
}
.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  /* background: url('/path-to-avatar.png') no-repeat center center; */
  background-size: cover;
  margin-left: 10px;
}
</style>
