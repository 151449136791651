<!-- src/components/ManagedChargers.vue -->
<template>
    <div class="managed-chargers">
      <h4>Managed Chargers</h4>
      <table>
        <thead>
          <tr>
            <th>Charger Name</th>
            <th>Outlet</th>
            <th>Status</th>
            <th>Total Charges</th>
            <th>Unique Users</th>
            <th>Charging Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>UkContact PSS Type 1</td>
            <td>N#1</td>
            <td class="status out-of-service">Out of Service</td>
            <td>497</td>
            <td>306</td>
            <td>240h 12min</td>
          </tr>
          <tr>
            <td>UkContact PSS Type 2</td>
            <td>N#2</td>
            <td class="status available">Available</td>
            <td>362</td>
            <td>250</td>
            <td>128h 58min</td>
          </tr>
          <!-- Add more rows as needed -->
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ManagedChargers',
  };
  </script>
  
  <style scoped>
  .managed-chargers {
    background: #fff;
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  th, td {
    padding: 10px;
    text-align: left;
  }
  .status {
    padding: 5px 10px;
    border-radius: 4px;
  }
  .status.out-of-service {
    background: red;
    color: #fff;
  }
  .status.available {
    background: green;
    color: #fff;
  }
  </style>
  